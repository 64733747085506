.page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background-color: #000;
    overflow: hidden;
}

.loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
}

.video {

}